import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M10.5216 11.1224C9.46309 12.0396 8.10892 12.5436 6.70833 12.5417C3.48658 12.5417 0.875 9.93008 0.875 6.70833C0.875 3.48658 3.48658 0.875 6.70833 0.875C9.93008 0.875 12.5417 3.48658 12.5417 6.70833C12.5417 7.95433 12.1508 9.10933 11.4858 10.0567L9.625 6.70833H11.375C11.3749 5.63279 11.0033 4.59029 10.3231 3.75719C9.64281 2.92408 8.69567 2.35153 7.64186 2.13638C6.58805 1.92122 5.49228 2.07668 4.5399 2.57645C3.58751 3.07622 2.837 3.88962 2.41531 4.87905C1.99361 5.86848 1.92664 6.9732 2.2257 8.00633C2.52477 9.03946 3.17152 9.93758 4.05655 10.5487C4.94158 11.1599 6.01055 11.4466 7.08263 11.3603C8.15471 11.2741 9.16408 10.8201 9.94 10.0753L10.5216 11.1224Z"
        fill={color}
      />
      <path
        d="M7.02605 4.375C7.32744 4.37493 7.62058 4.48413 7.86076 4.68595C8.10095 4.88776 8.275 5.17111 8.35638 5.49279C8.43776 5.81447 8.422 6.15683 8.3115 6.46765C8.201 6.77848 8.00183 7.04071 7.74436 7.21437L8.75 8.75H7.77436L6.91396 7.4375H6.03935V8.75H5.25V4.375H7.02605ZM7.02605 5.25H6.03935V6.5625H7.02605C7.17322 6.56249 7.31511 6.50172 7.42405 6.39204C7.53299 6.28236 7.60117 6.13163 7.6153 5.96925L7.61806 5.90625C7.61806 5.7322 7.55569 5.56528 7.44467 5.44221C7.33364 5.31914 7.18306 5.25 7.02605 5.25Z"
        fill={color}
      />
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.0247 12.7113C10.815 13.7596 9.26734 14.3356 7.66667 14.3333C3.98467 14.3333 1 11.3487 1 7.66667C1 3.98467 3.98467 1 7.66667 1C11.3487 1 14.3333 3.98467 14.3333 7.66667C14.3333 9.09067 13.8867 10.4107 13.1267 11.4933L11 7.66667H13C12.9999 6.43747 12.5752 5.24604 11.7978 4.29393C11.0204 3.34181 9.93791 2.68746 8.73355 2.44157C7.5292 2.19568 6.27689 2.37335 5.18845 2.94451C4.10002 3.51568 3.24228 4.44528 2.76035 5.57605C2.27842 6.70683 2.20187 7.96937 2.54366 9.15009C2.88545 10.3308 3.62459 11.3572 4.63605 12.0557C5.64751 12.7542 6.86919 13.0818 8.09443 12.9833C9.31966 12.8847 10.4732 12.3659 11.36 11.5147L12.0247 12.7113Z"
        fill={color}
      />
      <path
        d="M8.02977 5C8.37421 4.99993 8.70923 5.12472 8.98373 5.35537C9.25823 5.58601 9.45715 5.90984 9.55015 6.27747C9.64316 6.64511 9.62514 7.03637 9.49886 7.3916C9.37258 7.74683 9.14495 8.04653 8.8507 8.245L10 10H8.88498L7.90167 8.5H6.90212V10H6V5H8.02977ZM8.02977 6H6.90212V7.5H8.02977C8.19796 7.49999 8.36012 7.43054 8.48463 7.30519C8.60913 7.17984 8.68706 7.00758 8.7032 6.822L8.70636 6.75C8.70636 6.55109 8.63508 6.36032 8.50819 6.21967C8.38131 6.07902 8.20921 6 8.02977 6Z"
        fill={color}
      />
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13.5278 14.3002C12.1668 15.4795 10.4258 16.1275 8.625 16.125C4.48275 16.125 1.125 12.7673 1.125 8.625C1.125 4.48275 4.48275 1.125 8.625 1.125C12.7673 1.125 16.125 4.48275 16.125 8.625C16.125 10.227 15.6225 11.712 14.7675 12.93L12.375 8.625H14.625C14.6249 7.24216 14.1471 5.9018 13.2725 4.83067C12.3979 3.75954 11.1801 3.02339 9.82525 2.74677C8.47036 2.47014 7.0615 2.67001 5.83701 3.31257C4.61252 3.95513 3.64757 5.00093 3.10539 6.27306C2.56322 7.54519 2.4771 8.96554 2.86162 10.2939C3.24613 11.6222 4.07767 12.7769 5.21556 13.5627C6.35345 14.3485 7.72784 14.7171 9.10623 14.6062C10.4846 14.4953 11.7824 13.9116 12.78 12.954L13.5278 14.3002Z"
        fill={color}
      />
      <path
        d="M9.03349 5.625C9.42099 5.62492 9.79788 5.76532 10.1067 6.02479C10.4155 6.28426 10.6393 6.64857 10.7439 7.06216C10.8485 7.47575 10.8283 7.91592 10.6862 8.31555C10.5441 8.71518 10.2881 9.05235 9.95704 9.27562L11.25 11.25H9.9956L8.88938 9.5625H7.76488V11.25H6.75V5.625H9.03349ZM9.03349 6.75H7.76488V8.4375H9.03349C9.22271 8.43749 9.40514 8.35936 9.54521 8.21834C9.68527 8.07732 9.77294 7.88353 9.7911 7.67475L9.79465 7.59375C9.79465 7.36997 9.71446 7.15536 9.57172 6.99713C9.42897 6.83889 9.23536 6.75 9.03349 6.75Z"
        fill={color}
      />
    </svg>
  ),
  20: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.0308 15.8892C13.5187 17.1994 11.5842 17.9195 9.58333 17.9167C4.98083 17.9167 1.25 14.1858 1.25 9.58333C1.25 4.98083 4.98083 1.25 9.58333 1.25C14.1858 1.25 17.9167 4.98083 17.9167 9.58333C17.9167 11.3633 17.3583 13.0133 16.4083 14.3667L13.75 9.58333H16.25C16.2499 8.04684 15.719 6.55755 14.7472 5.36741C13.7754 4.17726 12.4224 3.35933 10.9169 3.05196C9.41151 2.7446 7.84611 2.96668 6.48557 3.68064C5.12502 4.39459 4.05285 5.55659 3.45044 6.97007C2.84802 8.38354 2.75234 9.96172 3.17958 11.4376C3.60681 12.9135 4.53074 14.1965 5.79507 15.0696C7.05939 15.9427 8.58649 16.3523 10.118 16.2291C11.6496 16.1058 13.0915 15.4574 14.2 14.3933L15.0308 15.8892Z"
        fill={color}
      />
      <path
        d="M10.0372 6.25C10.4678 6.24991 10.8865 6.40591 11.2297 6.69421C11.5728 6.98251 11.8214 7.3873 11.9377 7.84684C12.0539 8.30638 12.0314 8.79546 11.8736 9.2395C11.7157 9.68354 11.4312 10.0582 11.0634 10.3062L12.5 12.5H11.1062L9.87709 10.625H8.62765V12.5H7.5V6.25H10.0372ZM10.0372 7.5H8.62765V9.375H10.0372C10.2475 9.37499 10.4502 9.28818 10.6058 9.13149C10.7614 8.9748 10.8588 8.75948 10.879 8.5275L10.8829 8.4375C10.8829 8.18886 10.7938 7.9504 10.6352 7.77459C10.4766 7.59877 10.2615 7.5 10.0372 7.5Z"
        fill={color}
      />
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.037 19.067C16.2224 20.6393 13.901 21.5033 11.5 21.5C5.977 21.5 1.5 17.023 1.5 11.5C1.5 5.977 5.977 1.5 11.5 1.5C17.023 1.5 21.5 5.977 21.5 11.5C21.5 13.636 20.83 15.616 19.69 17.24L16.5 11.5H19.5C19.4998 9.65621 18.8628 7.86906 17.6967 6.44089C16.5305 5.01272 14.9069 4.03119 13.1003 3.66236C11.2938 3.29352 9.41533 3.56002 7.78268 4.41677C6.15002 5.27351 4.86342 6.66791 4.14052 8.36408C3.41762 10.0603 3.30281 11.9541 3.81549 13.7251C4.32818 15.4962 5.43689 17.0358 6.95408 18.0836C8.47127 19.1313 10.3038 19.6228 12.1416 19.4749C13.9795 19.327 15.7099 18.5488 17.04 17.272L18.037 19.067Z"
        fill={color}
      />
      <path
        d="M12.0447 7.5C12.5613 7.49989 13.0638 7.68709 13.4756 8.03305C13.8873 8.37902 14.1857 8.86476 14.3252 9.41621C14.4647 9.96766 14.4377 10.5546 14.2483 11.0874C14.0589 11.6202 13.7174 12.0698 13.276 12.3675L15 15H13.3275L11.8525 12.75H10.3532V15H9V7.5H12.0447ZM12.0447 9H10.3532V11.25H12.0447C12.2969 11.25 12.5402 11.1458 12.7269 10.9578C12.9137 10.7698 13.0306 10.5114 13.0548 10.233L13.0595 10.125C13.0595 9.82663 12.9526 9.54048 12.7623 9.3295C12.572 9.11853 12.3138 9 12.0447 9Z"
        fill={color}
      />
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M19.5401 20.6559C17.5743 22.3593 15.0594 23.2953 12.4583 23.2917C6.47508 23.2917 1.625 18.4416 1.625 12.4583C1.625 6.47508 6.47508 1.625 12.4583 1.625C18.4416 1.625 23.2917 6.47508 23.2917 12.4583C23.2917 14.7723 22.5658 16.9173 21.3308 18.6767L17.875 12.4583H21.125C21.1248 10.4609 20.4347 8.52482 19.1714 6.97763C17.9081 5.43044 16.1491 4.36713 14.192 3.96755C12.235 3.56798 10.1999 3.85669 8.43123 4.78483C6.66253 5.71297 5.26871 7.22357 4.48557 9.06109C3.70243 10.8986 3.57804 12.9502 4.13345 14.8689C4.68886 16.7876 5.88997 18.4555 7.53359 19.5905C9.17721 20.7256 11.1624 21.258 13.1534 21.0978C15.1445 20.9376 17.019 20.0946 18.46 18.7113L19.5401 20.6559Z"
        fill={color}
      />
      <path
        d="M13.0484 8.125C13.6081 8.12488 14.1525 8.32768 14.5986 8.70247C15.0446 9.07727 15.3679 9.60349 15.519 10.2009C15.6701 10.7983 15.6409 11.4341 15.4356 12.0114C15.2304 12.5886 14.8605 13.0756 14.3824 13.3981L16.25 16.25H14.4381L12.8402 13.8125H11.2159V16.25H9.75V8.125H13.0484ZM13.0484 9.75H11.2159V12.1875H13.0484C13.3217 12.1875 13.5852 12.0746 13.7875 11.8709C13.9898 11.6672 14.1165 11.3873 14.1427 11.0857L14.1478 10.9688C14.1478 10.6455 14.032 10.3355 13.8258 10.107C13.6196 9.8784 13.34 9.75 13.0484 9.75Z"
        fill={color}
      />
    </svg>
  ),
  28: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M21.0432 22.2448C18.9262 24.0792 16.2178 25.0872 13.4167 25.0833C6.97317 25.0833 1.75 19.8602 1.75 13.4167C1.75 6.97317 6.97317 1.75 13.4167 1.75C19.8602 1.75 25.0833 6.97317 25.0833 13.4167C25.0833 15.9087 24.3017 18.2187 22.9717 20.1133L19.25 13.4167H22.75C22.7498 11.2656 22.0066 9.18057 20.6461 7.51437C19.2856 5.84817 17.3913 4.70306 15.2837 4.27275C13.1761 3.84244 10.9846 4.15336 9.07979 5.15289C7.17503 6.15243 5.67399 7.77923 4.83061 9.75809C3.98723 11.737 3.85327 13.9464 4.45141 16.0127C5.04954 18.0789 6.34304 19.8752 8.1131 21.0975C9.88315 22.3198 12.0211 22.8932 14.1653 22.7207C16.3094 22.5482 18.3282 21.6403 19.88 20.1507L21.0432 22.2448Z"
        fill={color}
      />
      <path
        d="M14.0521 8.75C14.6549 8.74987 15.2412 8.96827 15.7215 9.37189C16.2019 9.77552 16.55 10.3422 16.7128 10.9856C16.8755 11.6289 16.844 12.3137 16.623 12.9353C16.402 13.557 16.0037 14.0814 15.4887 14.4287L17.5 17.5H15.5487L13.8279 14.875H12.0787V17.5H10.5V8.75H14.0521ZM14.0521 10.5H12.0787V13.125H14.0521C14.3464 13.125 14.6302 13.0034 14.8481 12.7841C15.066 12.5647 15.2023 12.2633 15.2306 11.9385L15.2361 11.8125C15.2361 11.4644 15.1114 11.1306 14.8893 10.8844C14.6673 10.6383 14.3661 10.5 14.0521 10.5Z"
        fill={color}
      />
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M24.0493 25.4227C21.6299 27.5191 18.5347 28.6711 15.3333 28.6667C7.96933 28.6667 2 22.6973 2 15.3333C2 7.96933 7.96933 2 15.3333 2C22.6973 2 28.6667 7.96933 28.6667 15.3333C28.6667 18.1813 27.7733 20.8213 26.2533 22.9867L22 15.3333H26C25.9998 12.8749 25.1504 10.4921 23.5956 8.58785C22.0407 6.68362 19.8758 5.37492 17.4671 4.88314C15.0584 4.39136 12.5538 4.74669 10.3769 5.88902C8.20003 7.03135 6.48456 8.89055 5.5207 11.1521C4.55683 13.4137 4.40374 15.9387 5.08732 18.3002C5.7709 20.6616 7.24919 22.7145 9.27211 24.1114C11.295 25.5084 13.7384 26.1637 16.1889 25.9665C18.6393 25.7693 20.9465 24.7318 22.72 23.0293L24.0493 25.4227Z"
        fill={color}
      />
      <path
        d="M16.0595 10C16.7484 9.99985 17.4185 10.2494 17.9675 10.7107C18.5165 11.172 18.9143 11.8197 19.1003 12.5549C19.2863 13.2902 19.2503 14.0727 18.9977 14.7832C18.7452 15.4937 18.2899 16.0931 17.7014 16.49L20 20H17.77L15.8033 17H13.8042V20H12V10H16.0595ZM16.0595 12H13.8042V15H16.0595C16.3959 15 16.7202 14.8611 16.9693 14.6104C17.2183 14.3597 17.3741 14.0152 17.4064 13.644L17.4127 13.5C17.4127 13.1022 17.2702 12.7206 17.0164 12.4393C16.7626 12.158 16.4184 12 16.0595 12Z"
        fill={color}
      />
    </svg>
  )
};
