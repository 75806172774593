import React from 'react';

export default {
  14: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28816)">
        <path
          d="M2.91671 12.8334C2.762 12.8334 2.61362 12.772 2.50423 12.6626C2.39483 12.5532 2.33337 12.4048 2.33337 12.2501V1.75008C2.33337 1.59537 2.39483 1.447 2.50423 1.3376C2.61362 1.22821 2.762 1.16675 2.91671 1.16675H11.0834C11.2381 1.16675 11.3865 1.22821 11.4959 1.3376C11.6053 1.447 11.6667 1.59537 11.6667 1.75008V3.50008H10.5V2.33341H3.50004V11.6667H10.5V10.5001H11.6667V12.2501C11.6667 12.4048 11.6053 12.5532 11.4959 12.6626C11.3865 12.772 11.2381 12.8334 11.0834 12.8334H2.91671ZM10.5 9.33341V7.58341H6.41671V6.41675H10.5V4.66675L13.4167 7.00008L10.5 9.33341Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28816">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  16: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28820)">
        <path
          d="M3.33329 14.6666C3.15648 14.6666 2.98691 14.5963 2.86189 14.4713C2.73686 14.3463 2.66663 14.1767 2.66663 13.9999V1.99992C2.66663 1.82311 2.73686 1.65354 2.86189 1.52851C2.98691 1.40349 3.15648 1.33325 3.33329 1.33325H12.6666C12.8434 1.33325 13.013 1.40349 13.138 1.52851C13.2631 1.65354 13.3333 1.82311 13.3333 1.99992V3.99992H12V2.66659H3.99996V13.3333H12V11.9999H13.3333V13.9999C13.3333 14.1767 13.2631 14.3463 13.138 14.4713C13.013 14.5963 12.8434 14.6666 12.6666 14.6666H3.33329ZM12 10.6666V8.66659H7.33329V7.33325H12V5.33325L15.3333 7.99992L12 10.6666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28820">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  18: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28824)">
        <path
          d="M3.75 16.5C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V4.5H13.5V3H4.5V15H13.5V13.5H15V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75ZM13.5 12V9.75H8.25V8.25H13.5V6L17.25 9L13.5 12Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28824">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  20: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28825)">
        <path
          d="M4.16659 18.3334C3.94557 18.3334 3.73361 18.2456 3.57733 18.0893C3.42105 17.9331 3.33325 17.7211 3.33325 17.5001V2.50008C3.33325 2.27907 3.42105 2.06711 3.57733 1.91083C3.73361 1.75455 3.94557 1.66675 4.16659 1.66675H15.8333C16.0543 1.66675 16.2662 1.75455 16.4225 1.91083C16.5788 2.06711 16.6666 2.27907 16.6666 2.50008V5.00008H14.9999V3.33341H4.99992V16.6667H14.9999V15.0001H16.6666V17.5001C16.6666 17.7211 16.5788 17.9331 16.4225 18.0893C16.2662 18.2456 16.0543 18.3334 15.8333 18.3334H4.16659ZM14.9999 13.3334V10.8334H9.16658V9.16675H14.9999V6.66675L19.1666 10.0001L14.9999 13.3334Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28825">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  24: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28836)">
        <path
          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28836">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  26: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_53625)">
        <path
          d="M5.41659 23.8334C5.12927 23.8334 4.85372 23.7193 4.65055 23.5161C4.44739 23.313 4.33325 23.0374 4.33325 22.7501V3.25008C4.33325 2.96276 4.44739 2.68721 4.65055 2.48405C4.85372 2.28088 5.12927 2.16675 5.41659 2.16675H20.5833C20.8706 2.16675 21.1461 2.28088 21.3493 2.48405C21.5524 2.68721 21.6666 2.96276 21.6666 3.25008V6.50008H19.4999V4.33341H6.49992V21.6667H19.4999V19.5001H21.6666V22.7501C21.6666 23.0374 21.5524 23.313 21.3493 23.5161C21.1461 23.7193 20.8706 23.8334 20.5833 23.8334H5.41659ZM19.4999 17.3334V14.0834H11.9166V11.9167H19.4999V8.66675L24.9166 13.0001L19.4999 17.3334Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_53625">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  28: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28837)">
        <path
          d="M5.83341 25.6666C5.524 25.6666 5.22725 25.5437 5.00846 25.3249C4.78966 25.1061 4.66675 24.8093 4.66675 24.4999V3.49992C4.66675 3.1905 4.78966 2.89375 5.00846 2.67496C5.22725 2.45617 5.524 2.33325 5.83341 2.33325H22.1667C22.4762 2.33325 22.7729 2.45617 22.9917 2.67496C23.2105 2.89375 23.3334 3.1905 23.3334 3.49992V6.99992H21.0001V4.66659H7.00008V23.3333H21.0001V20.9999H23.3334V24.4999C23.3334 24.8093 23.2105 25.1061 22.9917 25.3249C22.7729 25.5437 22.4762 25.6666 22.1667 25.6666H5.83341ZM21.0001 18.6666V15.1666H12.8334V12.8333H21.0001V9.33325L26.8334 13.9999L21.0001 18.6666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28837">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  32: ({ color }: { color: string }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clip-path="url(#clip0_6675_28848)">
        <path
          d="M6.66659 29.3334C6.31296 29.3334 5.97382 29.1929 5.72378 28.9429C5.47373 28.6928 5.33325 28.3537 5.33325 28.0001V4.00008C5.33325 3.64646 5.47373 3.30732 5.72378 3.05727C5.97382 2.80722 6.31296 2.66675 6.66659 2.66675H25.3333C25.6869 2.66675 26.026 2.80722 26.2761 3.05727C26.5261 3.30732 26.6666 3.64646 26.6666 4.00008V8.00008H23.9999V5.33341H7.99992V26.6667H23.9999V24.0001H26.6666V28.0001C26.6666 28.3537 26.5261 28.6928 26.2761 28.9429C26.026 29.1929 25.6869 29.3334 25.3333 29.3334H6.66659ZM23.9999 21.3334V17.3334H14.6666V14.6667H23.9999V10.6667L30.6666 16.0001L23.9999 21.3334Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6675_28848">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};
